import styled, { css } from "styled-components";
import Grid from "assets/homepage/landing/Grid2.svg";
import { typography } from "screens/Homepage/components/typography";
import { BlueHighlightedCSS, LinkMarketingButton } from "screens/Homepage/styles";
import { docsLink, dashboardLink } from "constants/Links";
import { ReactComponent as YCombinatorLogo } from "assets/y-combinator.svg";

const Hero: React.FC = () => {
  return (
    <Container>
      <MainInfo>
        <TitleWrapper>
          <HighlightedTitle $text="Open Serverless GPU Cloud">Open Serverless GPU Cloud</HighlightedTitle>
          <Title></Title>
        </TitleWrapper>
        <SubTitle>
          Deploy inference endpoints, train AI models, and autoscale to hundreds of GPUs. Run workloads in our
          cloud, or plug-in external GPU providers.
        </SubTitle>
        <ButtonGroup>
          <LinkMarketingButton href={dashboardLink} size="lg" icon="far-angle-right">
            Deploy Your App in 2 Minutes
          </LinkMarketingButton>
          {/* <LinkMarketingButton
            href={docsLink}
            buttonTheme="secondary"
            variant="outline"
            size="lg"
            icon="far-book-blank"
          >
            Documentation
          </LinkMarketingButton> */}
        </ButtonGroup>
        <BadgeContainer>
          <Text>Backed by</Text>
          <YCombinatorLogo height="40" />
        </BadgeContainer>
      </MainInfo>
    </Container>
  );
};

export default Hero;

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`;

const Text = styled.p`
  ${({ theme }) => {
    return css`
      color: ${theme.colors.gray11};
      ${typography.textLgLight};
    `;
  }}
`;

const Container = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      isolation: isolate;
      width: 100%;

      background-image: url(${Grid});
      background-size: cover;
      background-position: bottom;
      background-repeat: no-repeat;

      border-bottom: 1px solid rgba(255, 255, 255, 0.04);

      padding: 64px 16px 128px 16px;

      @media (min-width: ${theme.breakpoints.sm}px) {
        padding: 64px 32px 128px 32px;
      }

      @media (min-width: ${theme.breakpoints.md}px) {
        padding: 64px 48px 128px 48px;
      }

      @media (min-width: ${theme.breakpoints.lg}px) {
        padding: 80px 32px 160px 32px;
      }

      @media (min-width: ${theme.breakpoints.xl}px) {
        padding: 80px 64px 160px 64px;
      }
    `;
  }}
`;

const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Title = styled.h1`
  ${({ theme }) => {
    return css`
      all: unset;
      margin-bottom: 24px;
      color: ${theme.colors.gray12};
      text-align: center;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);

      max-width: 100%;

      ${typography.title56};

      @media (min-width: ${theme.breakpoints.sm}px) {
        ${typography.title72};
      }

      @media (min-width: ${theme.breakpoints.lg}px) {
        ${typography.title80};
      }
    `;
  }}
`;

const HighlightedTitle = styled(Title)<{ $text?: string }>`
  ${({ theme }) =>
    BlueHighlightedCSS(
      theme.colors.gray1,
      `0px 4px 96px rgba(83, 113, 231, 0.64), 0px 8px 192px rgba(83, 113, 231, 0.48),
          0px 0px 8px rgba(132, 157, 255, 0.48)`
    )};
`;

const SubTitle = styled.p`
  ${({ theme }) => {
    return css`
      all: unset;
      margin-bottom: 48px;
      color: ${theme.colors.gray11};
      max-width: 750px;

      ${typography.textLgLight};

      @media (min-width: ${theme.breakpoints.sm}px) {
        ${typography.textXlLight};
      }
    `;
  }}
`;

const ButtonGroup = styled.div`
  ${({ theme }) => {
    return css`
      display: grid;
      grid-gap: 16px;
      justify-items: center;
      grid-template-columns: auto auto;

      @media (max-width: ${theme.breakpoints.sm}px) {
        width: 100%;
        grid-template-columns: 1fr;

        a,
        button {
          width: 100%;
        }
      }
    `;
  }}
`;
