import styled, { css } from "styled-components";
import LinkButton from "components/LinkButton";
import { Button, TButtonTheme } from "@beamcloud/design-system";
import { hexToRgba } from "utils/ColorUtil";
import { typography } from "./components/typography";

export const SectionContainer = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      isolation: isolate;
      width: 100%;
      background: linear-gradient(
        ${hexToRgba(theme.colors.gray2, 1)} 0%,
        ${hexToRgba(theme.colors.gray1, 1)} 30%,
        ${hexToRgba(theme.colors.gray1, 1)} 100%
      );
      border-bottom: 1px solid rgba(255, 255, 255, 0.03);

      padding: 80px 16px;
      gap: 64px;

      @media (min-width: ${theme.breakpoints.sm}px) {
        padding: 96px 32px;
        gap: 80px;
      }

      @media (min-width: ${theme.breakpoints.md}px) {
        padding: 96px 48px;
        gap: 96px;
      }

      @media (min-width: ${theme.breakpoints.lg}px) {
        padding: 96px 32px;
      }

      @media (min-width: ${theme.breakpoints.xl}px) {
        padding: 96px 64px;
      }
    `;
  }}
`;

export const TopSectionContainer = styled(SectionContainer)`
  ${({ theme }) => {
    return css`
      padding-top: 40px;
      gap: 64px;

      @media (max-width: ${theme.breakpoints.sm}px) {
        gap: 48px;
      }

      @media (min-width: ${theme.breakpoints.sm}px) {
        padding-top: 48px;
      }
    `;
  }}
`;

export const SectionTitle = styled.h2<{
  $maxWidth?: React.CSSProperties["maxWidth"];
  $textAlign?: React.CSSProperties["textAlign"];
}>`
  ${({ theme, $maxWidth = "560px" }) => {
    return css`
      all: unset;
      color: ${theme.colors.gray12};
      width: 100%;
      max-width: ${$maxWidth};

      ${typography.title36};
      text-align: left;

      @media (min-width: ${theme.breakpoints.sm}px) {
        ${typography.title40};
        text-align: center;
      }

      @media (min-width: ${theme.breakpoints.md}px) {
        ${typography.title44};
      }

      @media (min-width: ${theme.breakpoints.lg}px) {
        ${typography.title48};
      }

      @media (min-width: ${theme.breakpoints.xl}px) {
        ${typography.title52};
      }
    `;
  }}
`;

export const SectionSubtitle = styled.p<{
  $maxWidth?: React.CSSProperties["maxWidth"];
  $textAlign?: React.CSSProperties["textAlign"];
}>`
  ${({ theme, $maxWidth = "640px", $textAlign = "center" }) => {
    return css`
      all: unset;
      color: ${theme.colors.gray11};
      text-align: ${$textAlign};
      max-width: ${$maxWidth};
      ${typography.textLgLight};

      @media (max-width: ${theme.breakpoints.sm}px) {
        text-align: left;
      }

      @media (min-width: ${theme.breakpoints.lg}px) {
        ${typography.textXlLight};
      }
    `;
  }}
`;

const MarketingButtonCSS = css<{ buttonTheme?: TButtonTheme }>`
  ${({ theme, buttonTheme = "primary" }) => {
    if (buttonTheme !== "primary") {
      return css``;
    }
    return css`
      display: inline-flex;
      background: linear-gradient(
        180deg,
        ${hexToRgba(theme.colors.blue9, 1)} 0%,
        ${hexToRgba(theme.colors.blue9, 0.64)} 100%
      );
      box-shadow: ${theme.shadows.base};
      backdrop-filter: blur(8px);
      ${theme.styleSet.textBasePlus};

      &:hover {
        background: linear-gradient(
          180deg,
          ${hexToRgba(theme.colors.blue10, 1)} 0%,
          ${hexToRgba(theme.colors.blue10, 0.64)} 100%
        );
      }
    `;
  }}
`;

export const LinkMarketingButton = styled(LinkButton)`
  && {
    ${MarketingButtonCSS}
  }
`;

export const MarketingButton = styled(Button)`
  && {
    ${MarketingButtonCSS};
  }
`;

export const BlueHighlightedCSS = (
  bgColor?: React.CSSProperties["backgroundColor"],
  shadow?: React.CSSProperties["textShadow"]
) => css<{
  $text?: string;
}>`
  ${({ theme, $text }) => {
    return css`
      position: relative;
      margin: 0;
      color: ${hexToRgba(theme.colors.blue11, 0.5)};
      z-index: 0;

      &:before {
        color: ${bgColor};
        text-shadow: ${shadow}, -1px -1px 0 ${theme.colors.blue11}, 1px -1px 0 ${theme.colors.blue11},
          -1px 1px 0 ${theme.colors.blue11}, 1px 1px 0 ${theme.colors.blue11};

        content: ${`"${$text}"`};
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: -1;
      }
    `;
  }}
`;
