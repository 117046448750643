import styled, { css } from "styled-components";
import { ITestimonial } from "./TestimonialCard";
import { typography } from "screens/Homepage/components/typography";
import EricMeier from "assets/homepage/landing/testimonials/EricMeier.jpeg";
import JamesBonner from "assets/homepage/landing/testimonials/JamesBonner.jpeg";
import LeonardoCuco from "assets/homepage/landing/testimonials/LeonardoCuco.jpeg";
import BrandonBrisbon from "assets/homepage/landing/testimonials/BrandonBrisbon.jpeg";
import RemiCaland from "assets/homepage/landing/testimonials/RemiCaland.jpeg";
import BenSmith from "assets/homepage/landing/testimonials/BenjaminSmith.png";
import DevonPeroutky from "assets/homepage/landing/testimonials/DevonProutky.jpeg";
import BrandonGarcia from "assets/homepage/landing/testimonials/BrandonGarcia.jpeg";
import Frankie from "assets/homepage/landing/testimonials/frankie.jpeg";
import Louis from "assets/homepage/landing/testimonials/louis.jpeg";
import PauBajo from "assets/homepage/landing/testimonials/PauLabartaBajo.jpeg";
import Joshua from "assets/homepage/landing/testimonials/@joshuacc.jpeg";
import Liam from "assets/homepage/landing/testimonials/liam.jpeg";

const BeamCloud = styled.a.attrs({
  href: "https://twitter.com/beam_cloud",
  children: "@beam_cloud"
})`
  ${({ theme }) => {
    return css`
      all: unset;
      color: ${theme.colors.blue11} !important;
      cursor: pointer;
      ${typography.textBaseLight}
    `;
  }}
`;

const testimonials: ITestimonial[] = [
  {
    name: "Louis Morganer",
    userName: "CTO at Jamie",
    avatarImg: Louis,
    testimonial: (
      <>
        Beam is powering hands-down the best developer experience to run models on GPUs easily at scale. Best
        decision on the infra side for us this year so far.
      </>
    )
  },
  {
    name: "Eric Meier",
    userName: "@bitphinix",
    avatarImg: EricMeier,
    testimonial: (
      <>
        <BeamCloud /> is 🔥. Such a huge workflow improvement over AWS Sagemaker / Google vertex ai
      </>
    )
  },
  {
    name: "Brandon Garcia",
    userName: "@__BCG__",
    avatarImg: BrandonGarcia,
    testimonial: (
      <>
        One of the better developer experiences I've had in a while was with <BeamCloud /> - a serverless GPU
        and API infra platform. Check them out 👇
        <br />
        <br />
        Deploy an open source model on hugging face running on GPUs in a few minutes with 6 lines of code.
        <br />
        <br />
        Keep your eyes on these guys 👀
      </>
    )
  },
  {
    name: "James Bonner",
    userName: "Founder at Happy Accidents",
    avatarImg: JamesBonner,
    testimonial: (
      <>
        I can't recommend Beam highly enough. Their developer experience is top notch.
        <br></br>
        <br></br>
        We never could have shipped Happy Accidents as quickly as we did without them. We were able to build
        the GPU portion of our app in hours instead of weeks.
        <br></br>
        <br></br>
        Not only is the platform great, we loved working with the Beam team. They're extremely responsive, so
        we had a high level of confidence in the reliability of the platform.
      </>
    )
  },
  {
    name: "Liam Eloie",
    userName: "Machine Learning Engineer",
    avatarImg: Liam,
    testimonial: (
      <>
        Beam has been a huge time-saver by eliminating the need to monitor and manage my own VM
        infrastructure.
        <br></br>
        <br></br>I no longer worry about unexpected bugs or outages which means less downtime and fewer
        headaches.
        <br></br>
        <br></br>
        This lets me provide a significantly more reliable service to my users, and it's been surprisingly
        more cost-efficient than my prior solution.
      </>
    )
  },
  {
    name: "Benjamin Smith",
    userName: "MLE at Shippabo",
    avatarImg: BenSmith,
    testimonial: (
      <>
        Time is the biggest thing Beam has helped us with. I went from spending 6 hours developing an API to
        pressing a button and deploying instantly
      </>
    )
  },
  // {
  //   name: "Rémi Caland",
  //   userName: "CTO at Theseus AI",
  //   avatarImg: RemiCaland,
  //   testimonial: (
  //     <>
  //       Beam is perfect for people trying to launch new AI products
  //       <br></br>
  //       <br></br>
  //       It's easy to deploy, you don't need docker
  //       <br></br>
  //       <br></br>
  //       And you can develop your POC super fast
  //     </>
  //   )
  // },
  {
    name: "Brandon Brisbon",
    userName: "CTO at Shop Galaxy",
    avatarImg: BrandonBrisbon,
    testimonial: (
      <>
        Spun up a new app today and realized just how it easy it was. Took me only 15 mins to organize and
        deploy on Beam. <br></br>
        <br></br>Realizing that quick python apps on Beam is a cheat code
      </>
    )
  },
  {
    name: "Devon Peroutky",
    userName: "Software Engineer",
    avatarImg: DevonPeroutky,
    testimonial: (
      <>Beam has been a revelation in terms of making it simple to build an ML application on GPU</>
    )
  },
  {
    name: "Frankie L.",
    userName: "CTO and AI Researcher @ Frase",
    avatarImg: Frankie,
    testimonial: (
      <>
        Frase is running language models exclusively on Beam and it was surprisingly easy to migrate, less
        maintenance, and is saving us money because unlike Google and other cloud providers, Beam is able to
        provide us with an on-demand solution that scales immediately with our traffic, and we don’t need to
        worry about any of the clunky tooling around GPUs.
      </>
    )
  },
  {
    name: "Leonardo Cuco",
    userName: "CTO at Ween.ai",
    avatarImg: LeonardoCuco,
    testimonial: (
      <>
        Beam is amazing. I tested the CLI and in 5 minutes had something running on the cloud.
        <br />
        <br />
        And the Slack community is a game changer because when we get stuck we get responses quickly
      </>
    )
  },
  {
    name: "Joshua Clanton",
    userName: "@joshuacc",
    avatarImg: Joshua,
    testimonial: (
      <>
        If you're looking to dip your toes into building something with AI, definitely take a look at
        http://beam.cloud.
        <br />
        <br />
        Serverless functions with access to GPUs so you can run jobs on-demand and pay only for what you use.
        <br />
        <br />
        And it's *much* easier than setting up a VM somewhere!
      </>
    )
  }
  // {
  //   name: "Pau Labarta Bajo",
  //   userName: "@paulabartabajo_",
  //   avatarImg: PauBajo,
  //   testimonial: (
  //     <>
  //       Deploying ML apps in the "traditional AWS cloud" way requires:
  //       <br></br>
  //       <br></br>- Setting up IAM roles
  //       <br></br>- Writing Docker files
  //       <br></br>- Spinning up services like Lambda or API Gateway
  //       <br></br>
  //       <br></br>
  //       which is HARD
  //       <br></br>
  //       <br></br>
  //       This is when <BeamCloud /> becomes a game changer 🧠
  //     </>
  //   )
  // }
];

export default testimonials;
