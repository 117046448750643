import React, { useEffect } from "react";
import styled from "styled-components";
import { Typography } from "@beamcloud/design-system";
import { animate, motion, useMotionValue } from "framer-motion";
import { useMeasure } from "react-use";
import ShippaboLogo from "assets/shippabo.svg";
import StratumLogo from "assets/stratum.svg";
import Coke from "assets/coke.svg";
import Frase from "assets/frase.svg";
import Flowt from "assets/flowt.svg";
import Geospy from "assets/geospy.svg";
import Magellan from "assets/magellan.svg";

const Logos = [
  {
    src: Frase,
    alt: "Frase"
  },
  {
    src: StratumLogo,
    alt: "StratumAI"
  },
  {
    src: ShippaboLogo,
    alt: "Shippabo"
  },
  {
    src: Magellan,
    alt: "Magellan"
  },
  {
    src: Geospy,
    alt: "Geospy"
  },
  {
    src: Flowt,
    alt: "Flowt"
  },
  {
    src: Coke,
    alt: "Coke"
  }
  // {
  //   src: Kula,
  //   alt: "Kula"
  // }
];

interface IProps {}

const InvestorsCarousel: React.FC<IProps> = () => {
  const [carouselRef, { width }] = useMeasure<HTMLDivElement>();

  const xTranslation = useMotionValue(0);

  useEffect(() => {
    const finalPosition = -width / 2 - 96;
    console.log("finalPosition", finalPosition);
    console.log(xTranslation);

    const controls = animate(xTranslation, [finalPosition / 4, finalPosition], {
      ease: "linear",
      duration: 90,
      repeat: Infinity,
      repeatType: "loop",
      repeatDelay: 0
    });

    return controls?.stop;
  }, [width, xTranslation]);

  console.log(xTranslation);

  return (
    <Container>
      <Typography tag="h6" variant="headingSm" color="gray11">
        TRUSTED BY THOUSANDS OF DEVELOPERS
      </Typography>
      <InvestorLogoGroup
        ref={carouselRef}
        style={{
          x: xTranslation
        }}
      >
        {[...Logos, ...Logos].map(({ ...imgProps }, index) => {
          return <InvestorLogo key={index} {...imgProps} />;
        })}
      </InvestorLogoGroup>
    </Container>
  );
};

export default InvestorsCarousel;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: calc(35px + 48px);
`;

const InvestorLogoGroup = styled(motion.div)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 84px;
  left: 0;
  bottom: 0;
`;

const InvestorLogo = styled(motion.img)``;
